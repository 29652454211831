import router from '@/router'
import AuthService from '@/services/AuthService.js'
import UserService from '@/services/UserService.js'
import API from '@/services/API.js'
import * as Sentry from '@sentry/vue'

export const namespaced = true

export const state = {
  user: {},
  users: [],
  authenticated: false,
  hasTimeoutError: false,
  hasVersionError: false,
  token: '',
  domain: '',
  allDomains: [/* OBS: tänka på att id sparas i localstorage, så kan inte t.ex. byta till en annan camping och behålla id */
    /* TODO: [CO-2856] server domäner */
    ...(process.env.NODE_ENV !== 'production'
      ? [{
          id: 2,
          name: 'Lokal Testserver',
          domain: 'server.test',
          baseURL: 'https://server.test/api/',
          namespace: 'bastad'
        }]
      : []),
    ...(!window.hardware
      ? [{
          id: 3,
          name: 'CampingOnline Testserver',
          domain: 'demo.campingonline.se',
          baseURL: 'https://demo.campingonline.se/api/',
          namespace: 'demo'
        }]
      : []),
    {
      id: 4,
      name: 'Lerbergets Camping',
      domain: 'lerberget.campingonline.se',
      baseURL: 'https://lerberget.campingonline.se/api/',
      namespace: 'lerberget'
    },
    {
      id: 5,
      name: 'Båstad Camping',
      domain: 'bastad.campingonline.se',
      baseURL: 'https://bastad.campingonline.se/api/',
      namespace: 'bastad'
    },
    {
      id: 6,
      name: 'Trellebystrands Camping',
      domain: 'trellebystrand.campingonline.se',
      baseURL: 'https://trellebystrand.campingonline.se/api/',
      namespace: 'trellebystrand'
    },
    {
      id: 7,
      name: 'Råbocka Camping',
      domain: 'rabocka.campingonline.se',
      baseURL: 'https://rabocka.campingonline.se/api/',
      namespace: 'rabocka'
    },
    {
      id: 8,
      name: 'Böda Hamns Camping',
      domain: 'bodahamn.campingonline.se',
      baseURL: 'https://bodahamn.campingonline.se/api/',
      namespace: 'bodahamn'
    },
    // OBS: id 9 och 10 är använda för demos
    {
      id: 11,
      name: 'Funäsdalens Fjällcamping',
      domain: 'funasdalen.campingonline.se',
      baseURL: 'https://funasdalen.campingonline.se/api/',
      namespace: 'funasdalen'
    },
    {
      id: 12,
      name: 'Östanå Camping',
      domain: 'ostana.campingonline.se',
      baseURL: 'https://ostana.campingonline.se/api/',
      namespace: 'ostana'
    },
    {
      id: 13,
      name: 'Lunnedet Camping',
      domain: 'lunnedet.campingonline.se',
      baseURL: 'https://lunnedet.campingonline.se/api/',
      namespace: 'lunnedet'
    },
    {
      id: 14,
      name: 'Nya Skogsgården Camping',
      domain: 'nyaskogsgarden.campingonline.se',
      baseURL: 'https://nyaskogsgarden.campingonline.se/api/',
      namespace: 'nyaskogsgarden'
    },
    {
      id: 15,
      name: 'Orebackens Camping',
      domain: 'orebacken.campingonline.se',
      baseURL: 'https://orebacken.campingonline.se/api/',
      namespace: 'orebacken'
    },
    {
      id: 16,
      name: 'Visit Örestrand',
      domain: 'orestrand.campingonline.se',
      baseURL: 'https://orestrand.campingonline.se/api/',
      namespace: 'orestrand'
    },
    {
      id: 17,
      name: 'Skärshults Camping',
      domain: 'skarshult.campingonline.se',
      baseURL: 'https://skarshult.campingonline.se/api/',
      namespace: 'skarshult'
    },
    {
      id: 18,
      name: 'Skäralids Camping',
      domain: 'skaralid.campingonline.se',
      baseURL: 'https://skaralid.campingonline.se/api/',
      namespace: 'skaralid'
    }
  ],
  shortlistDomains: []
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user

    // Om det är lokalt så tar vi user från server istället, kanske användbart med mer centraliserad inloggning senare också
    if (process.env.NODE_ENV !== 'production') {
      state.allDomains.find(d => d.id === 2).namespace = user.namespace
    }

    localStorage.setItem('AUTH_USER', JSON.stringify(user))
    if (window.hardware) {
      window.hardware.setConfig('user', JSON.stringify(user))
    }
    Sentry.setUser({ username: user.email })
  },
  REMOVE_USER (state) {
    state.user = null
    localStorage.removeItem('AUTH_USER')
    if (window.hardware) {
      window.hardware.setConfig('user', null)
    }
  },
  SET_USERS (state, users) {
    state.users = users
  },
  SET_HAS_TIMEOUT_ERROR (state, val) {
    state.hasTimeoutError = val
  },
  SET_HAS_VERSION_ERROR (state, val) {
    state.hasVersionError = val
  },
  SET_AUTHENTICATED_STATUS (state, val) {
    const valString = val ? '1' : '0'
    document.cookie = `authenticated = ${valString};`
    state.authenticated = val
  },
  SET_TOKEN (state, token) {
    localStorage.setItem('AUTH_TOKEN', token)
    if (window.hardware) {
      window.hardware.setConfig('token', token)
    }
    state.token = token
    API.httpClient.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  REMOVE_TOKEN (state) {
    state.token = null
    localStorage.removeItem('AUTH_TOKEN')
    if (window.hardware) {
      window.hardware.setConfig('token', null)
    }
    API.httpClient.defaults.headers.common.Authorization = 'Bearer '
  },
  SET_DOMAIN (state, domain) {
    state.domain = domain
    Sentry.setTag('domain', domain.baseURL)
    API.httpClient.defaults.baseURL = domain.baseURL
    localStorage.setItem('AUTH_DOMAIN_ID', domain.id)
    if (window.hardware) {
      window.hardware.setConfig('domain_id', domain.id)
    }
  },
  ADD_SHORTLIST_DOMAIN (state, domainId) {
    const shortlist = state.shortlistDomains
    if (shortlist.find(sd => sd.id === domainId) != null) {
      // Finns redan i shortlist
      return false
    }
    const domain = state.allDomains.find(d => d.id === domainId)
    if (!domain) {
      // Fanns ingen domain med detta id, weird
      return false
    }
    shortlist.push(domain)
    localStorage.setItem('AUTH_SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    if (window.hardware) {
      window.hardware.setConfig('SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    }
    state.shortlistDomains = shortlist
  },
  UPDATE_SHORTLIST_DOMAIN (state, { domainId, lastUsedAt, lastUsedEmail }) {
    const shortlist = state.shortlistDomains
    const domain = shortlist.find(sd => sd.id === domainId)
    if (!domain) {
      // domain inte tillagd i shortlist ännu
      return false
    }
    domain.lastUsedAt = lastUsedAt
    domain.lastUsedEmail = lastUsedEmail
    const domainIndex = shortlist.findIndex(sd => sd.id === domainId)
    shortlist.splice(domainIndex, 1, domain)
    localStorage.setItem('AUTH_SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    if (window.hardware) {
      window.hardware.setConfig('SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    }
    state.shortlistDomains = shortlist
  },
  REMOVE_SHORTLIST_DOMAIN (state, domainId) {
    const shortlist = state.shortlistDomains
    const domain = shortlist.find(d => d.id === domainId)
    const domainIndex = shortlist.findIndex(d => d.id === domainId)
    if (!domain || domainIndex < 0) {
      // Fanns ingen domain i shortlist med detta id, weird
      return false
    }
    shortlist.splice(domainIndex, 1)
    localStorage.setItem('AUTH_SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    if (window.hardware) {
      window.hardware.setConfig('SHORTLIST_DOMAINS', JSON.stringify(shortlist))
    }
    state.shortlistDomains = shortlist
  }
}

export const actions = {
  initialise ({ state, commit, dispatch }) {
    let authenticatedString = null
    const name = 'authenticated='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        authenticatedString = c.substring(name.length, c.length)
      }
    }
    commit('SET_AUTHENTICATED_STATUS', authenticatedString === '1')

    let str = (window.hardware && window.hardware.getConfig('user')) || localStorage.getItem('AUTH_USER')
    if (str !== 'undefined' && str !== 'null' && str !== '') {
      const user = JSON.parse(str)
      if (user) {
        console.log('user:', user)
        commit('SET_USER', user)
      }
    } else {
      commit('REMOVE_USER')
    }

    // TODO: hämta om workstation vid refresh kanske?
    str = (window.hardware && window.hardware.getConfig('AUTH_WORKSTATION')) || localStorage.getItem('AUTH_WORKSTATION')
    if (str !== 'undefined' && str !== 'null' && str !== '') {
      const workstation = JSON.parse(str)
      if (workstation) {
        console.log('workstation:', workstation)
        commit('SET_WORKSTATION', workstation, { root: true })
      }
    } else {
      commit('SET_WORKSTATION', null, { root: true })
    }

    const tokenString = (window.hardware && window.hardware.getConfig('token')) || localStorage.getItem('AUTH_TOKEN')
    if (tokenString) {
      commit('SET_TOKEN', tokenString)
    }

    let localShortlistDomains = (window.hardware && window.hardware.getConfig('SHORTLIST_DOMAINS')) || localStorage.getItem('AUTH_SHORTLIST_DOMAINS')
    if (localShortlistDomains != null && localShortlistDomains !== 'undefined' && localShortlistDomains !== 'null' && localShortlistDomains !== '') {
      localShortlistDomains = JSON.parse(localShortlistDomains)
      const shortlistDomains = []
      // Hämtar domains från allDomains istället för att sätta direkt från localstorage, så en domain inte ligger kvar om vi har tagit bort eller uppdaterat den i allDomains
      localShortlistDomains.forEach(sd => {
        const domain = state.allDomains.find(d => d.id === sd.id)
        if (domain) {
          domain.lastUsedAt = window.dayjs(sd.lastUsedAt)
          domain.lastUsedEmail = sd.lastUsedEmail
          shortlistDomains.push(domain)
        }
      })
      localStorage.setItem('AUTH_SHORTLIST_DOMAINS', JSON.stringify(shortlistDomains))
      if (window.hardware) {
        window.hardware.setConfig('SHORTLIST_DOMAINS', JSON.stringify(shortlistDomains))
      }
      state.shortlistDomains = shortlistDomains
    }

    const cacheDomainId = (window.hardware && window.hardware.getConfig('domain_id')) || localStorage.getItem('AUTH_DOMAIN_ID')
    if (cacheDomainId) {
      const domain = state.allDomains.find(({ id }) => id === parseInt(cacheDomainId))
      if (domain) {
        commit('SET_DOMAIN', domain)
      }
    }

    if (state.authenticated) {
      dispatch('fetchData', { quickly: false }, { root: true })
    }
  },
  login ({ commit, dispatch, rootState }, credentials) {
    commit('SET_DOMAIN', credentials.domain)
    const machineId = (window.hardware && window.hardware.getConfig('AUTH_MACHINE_ID')) || localStorage.getItem('AUTH_MACHINE_ID')
    let hostname = ''
    if (rootState.hasHardwareAccess) {
      hostname = window.hardware.hostname
    }
    return AuthService.login(credentials, machineId, hostname)
      .then(({ data }) => {
        console.log('login:', data)
        if (data) {
          console.log('has data')
          commit('SET_USER', data.user)
          commit('SET_TOKEN', data.token)
          commit('SET_WORKSTATION', data.workstation, { root: true })
          commit('SET_AUTHENTICATED_STATUS', true)
          dispatch('fetchData', { quickly: false }, { root: true })
        } else {
          console.log('has no data')
          commit('REMOVE_USER')
          commit('REMOVE_TOKEN')
          commit('SET_WORKSTATION', null, { root: true })
          commit('SET_AUTHENTICATED_STATUS', false)
        }
      })
  },
  reconnect ({ commit, dispatch, rootState }) {
    const machineId = (window.hardware && window.hardware.getConfig('AUTH_MACHINE_ID')) || localStorage.getItem('AUTH_MACHINE_ID')
    let hostname = ''
    if (rootState.hasHardwareAccess) {
      hostname = window.hardware.hostname
    }
    return AuthService.reconnect(machineId, hostname)
      .then(({ data }) => {
        console.log('reconnect:', data)
        if (data) {
          commit('SET_USER', data.user)
          commit('SET_AUTHENTICATED_STATUS', true)
          commit('SET_WORKSTATION', data.workstation, { root: true })
          dispatch('fetchData', { quickly: false }, { root: true })
        } else {
          commit('REMOVE_USER')
          commit('REMOVE_TOKEN')
          commit('SET_WORKSTATION', null, { root: true })
          commit('SET_AUTHENTICATED_STATUS', false)
        }
        return data
      })
  },
  logout ({ commit }) {
    // Rensar sökhistorik när man själv loggar ut, byter troligtvis användare annars behöver man ju inte logga ut
    // Blir konstigt när Råbocka resultat ligger i Lerbergetinlogg och ger errors ibland
    localStorage.removeItem('RECENT_SEARCH_RESULTS')
    return AuthService.logout()
      .finally(() => {
        commit('SET_AUTHENTICATED_STATUS', false)
        commit('REMOVE_USER')
        commit('REMOVE_TOKEN')
        router.push('/')
        window.location.reload() // Detta är för att återställa vue store, så vi inte sparar state om användaren loggar in på en annan domän
      })
  },
  fetchUsers ({ commit }) {
    UserService.getUsers()
      .then(({ data }) => {
        commit('SET_USERS', data.data.users)
      })
  }
}
